<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="customerPersonDialog"
      :max-width="dialogWidth"
      content-class="customer-person-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">
            Select a Contact Person
          </h4>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            color="cyan"
            v-on:click="
              $emit('close', true);
              $emit('resetAll', true);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which contact person would you like to create this for?
              </h5>
              <table width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-customer-person"
                      v-model.trim="contactPerson"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <template
                    v-if="false && getPermission('contact-person:create')"
                  >
                    <td width="50" class="custom-header-blue-text text-center">
                      OR
                    </td>
                    <td>
                      <v-btn
                        class="mx-2 mx-md-4 custom-bold-button white--text"
                        v-on:click="createPersonDialog = true"
                        color="cyan"
                      >
                        <v-icon dark left>mdi-plus</v-icon>Contact Person
                      </v-btn>
                    </td>
                  </template>
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <v-list class="mx-2 mx-md-4" subheader three-line>
                    <v-skeleton-loader
                      v-if="searchLoader"
                      class="custom-skeleton"
                      type="list-item-two-line"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <template v-if="customerPersonList.length > 0">
                        <v-list-item
                          v-on:click="selectCustomerPerson(data)"
                          v-for="(data, index) in customerPersonList"
                          :key="index"
                          link
                        >
                          <v-list-item-icon class="mr-3">
                            <v-icon large>mdi-account</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <div>
                              <Barcode
                                class="my-0 mt-0 mb-0 mr-1"
                                :barcode="data.barcode"
                              ></Barcode>
                            </div>
                            <v-list-item-title
                              class="text-capitalize font-weight-500 font-size-16"
                              >{{ data.display_name }}</v-list-item-title
                            >
                            <div>
                              <v-chip
                                v-if="data.tenant"
                                small
                                color="green white--text"
                                label
                                class="tenant-small-chip"
                                >Tenant</v-chip
                              >
                            </div>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-subtitle v-if="data.primary_email"
                              class="text-lowercase font-weight-500 font-size-15"
                              >{{ data.primary_email }}</v-list-item-subtitle
                            >
                            <em v-else class="text-muted">
                                 no email
                            </em>
                            <v-list-item-subtitle  v-if="data.primary_phone"
                              class="text-lowercase font-weight-500 font-size-15"
                              >{{ data.primary_phone }}
                              </v-list-item-subtitle
                            >
                            <em v-else class="text-muted">
                                 no phone number
                            </em>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <template v-else>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-weight-500 font-size-16"
                              >No Contact Person(s) Found
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </template>
                  </v-list>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Dialog
      v-if="createPersonDialog"
      :commonDialog="createPersonDialog"
      :dialogWidth="768"
    >
      <template v-slot:title>Create Contact Person</template>
      <template v-slot:body>
        <v-form
          ref="createPerson"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createPerson"
        >
          <DialogContactPersons
            :isDialog="true"
            v-on:saveContactPerson="updateContactPerson"
          >
          </DialogContactPersons>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="createPerson"
          >Save Contact Person
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="createPersonDialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { PUT, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogContactPersons from "@/view/pages/customer/create-or-update/Contact-Persons.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CustomerMixin],
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      contactPerson: null,
      timeoutLimit: 500,
      timeout: null,
      personFormData: new Object(),
      createPersonDialog: false,
      customerPersonList: new Array(),
    };
  },
  watch: {
    contactPerson(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomerPerson();
        }, _this.timeoutLimit);
      }
    },
    customer() {
      this.searchCustomerPerson();
    },
  },
  components: {
    Dialog,
    Barcode,
    DialogContactPersons,
  },
  props: {
    disabledAutoSelect: {
      type: Boolean,
      default: false,
    },
    customerPersonDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    customer: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchCustomerPerson();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchCustomerPerson();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.contactPerson = null;
        _this.isFocused = false;
      });
    },
    searchCustomerPerson() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getCustomerPersons(_this.customer, _this.contactPerson)
        .then((response) => {
          _this.customerPersonList = response;
          if (
            !_this.disabledAutoSelect &&
            _this.customerPersonList.length === 1
          ) {
            _this.selectCustomerPerson(_this.customerPersonList[0]);
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.createPersonDialog = false;
          _this.searchLoader = false;
        });
    },
    selectCustomerPerson(param) {
      this.$emit("selectCustomerPerson", param.id);
    },
    updateContactPerson(param) {
      this.personFormData = param;
    },
    createPerson() {
      const _this = this;
      if (!_this.$refs.createPerson.validate()) {
        return false;
      }

      if (_this.lodash.isEmpty(_this.personFormData) === false) {
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        _this.$store
          .dispatch(PUT, {
            url: "customer/" + _this.customer + "/contact-person",
            data: { persons: _this.personFormData },
          })
          .then(() => {
            _this.searchCustomerPerson();
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
  },
  mounted() {
    this.searchCustomerPerson();
    this.$refs["search-customer-person"].focus();
  },
  computed: {
    isSearching() {
      return this.contactPerson && this.contactPerson.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Contact Person...";
    },
  },
};
</script>
